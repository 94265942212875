body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,body{
	height:100%;
}

body {
	background: #191d26; /* FF3.6-15 */
	background: -webkit-radial-gradient(
		center,
		ellipse farthest-corner,
		rgba(42, 51, 71, 1) 0%,
		rgba(25, 29, 38, 1) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: -webkit-radial-gradient(
		center,
		ellipse,
		rgba(42, 51, 71, 1) 0%,
		rgba(25, 29, 38, 1) 100%
	);
	background: radial-gradient(
		ellipse at center,
		rgba(42, 51, 71, 1) 0%,
		rgba(25, 29, 38, 1) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2a3347', endColorstr='#191d26',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	color:#E2E2E2;
}

#root{
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
}

.todo-application{
	width:100%;
	height:100%;
}

button:focus{
	outline:none;
}

.todo-list{
	list-style:none;
	margin:0;
	padding:0;
}

.todo-lists{
	position:relative;
	background-color:rgba(0,0,0,0.15);
	border-right:1px rgba(255,255,255,0.25) solid;
	border-bottom:1px rgba(255,255,255,0.25) solid;
}

.todo-lists > div{
	position:absolute;
	left:15px;
	bottom:1rem;
	top:1rem;
	right:15px;
	text-align:center;
}

.todo-lists > div > a{
	position:absolute;
	bottom:15px;
	width:100%;
	display:block;
	text-align:center;
}

.todo-lists:after{
	content: " ";
	display:block;
	padding-bottom:100%;
}

@media screen and (max-width:767px){
	.todo-lists:nth-child(even){
		border-right:0;
	}
}

.todo-item{
	border-bottom:1px rgba(255,255,255,0.5) solid;
	padding:.5rem 1rem;
}

.todo-item .todo-check{
	border-radius:50%;
	border:1px #000 solid;
}
.styled-checkbox {
  position: absolute;
  opacity: 0; }
  .styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0; }
  .styled-checkbox + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white; }
  .styled-checkbox:hover + label:before {
    background: #f35429; }
  .styled-checkbox:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    background: white; }
  .styled-checkbox:checked + label:before {
    background: #f35429; }
  .styled-checkbox:checked + label {
    text-decoration: line-through;
    font-style: italic; }
  .styled-checkbox:disabled + label {
    color: #b8b8b8;
    cursor: auto; }
  .styled-checkbox:disabled + label:before {
    box-shadow: none;
    background: #ddd; }
  .styled-checkbox:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }

