html,body{
	height:100%;
}

body {
	background: #191d26;
	background: -moz-radial-gradient(
		center,
		ellipse farthest-corner,
		rgba(42, 51, 71, 1) 0%,
		rgba(25, 29, 38, 1) 100%
	); /* FF3.6-15 */
	background: -webkit-radial-gradient(
		center,
		ellipse farthest-corner,
		rgba(42, 51, 71, 1) 0%,
		rgba(25, 29, 38, 1) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: radial-gradient(
		ellipse at center,
		rgba(42, 51, 71, 1) 0%,
		rgba(25, 29, 38, 1) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2a3347', endColorstr='#191d26',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	color:#E2E2E2;
}

#root{
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
}

.todo-application{
	width:100%;
	height:100%;
}

button:focus{
	outline:none;
}

.todo-list{
	list-style:none;
	margin:0;
	padding:0;
}

.todo-lists{
	position:relative;
	background-color:rgba(0,0,0,0.15);
	border-right:1px rgba(255,255,255,0.25) solid;
	border-bottom:1px rgba(255,255,255,0.25) solid;
}

.todo-lists > div{
	position:absolute;
	left:15px;
	bottom:1rem;
	top:1rem;
	right:15px;
	text-align:center;
}

.todo-lists > div > a{
	position:absolute;
	bottom:15px;
	width:100%;
	display:block;
	text-align:center;
}

.todo-lists:after{
	content: " ";
	display:block;
	padding-bottom:100%;
}

@media screen and (max-width:767px){
	.todo-lists:nth-child(even){
		border-right:0;
	}
}

.todo-item{
	border-bottom:1px rgba(255,255,255,0.5) solid;
	padding:.5rem 1rem;
}

.todo-item .todo-check{
	border-radius:50%;
	border:1px #000 solid;
}