.styled-checkbox {
  position: absolute;
  opacity: 0; }
  .styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0; }
  .styled-checkbox + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white; }
  .styled-checkbox:hover + label:before {
    background: #f35429; }
  .styled-checkbox:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    background: white; }
  .styled-checkbox:checked + label:before {
    background: #f35429; }
  .styled-checkbox:checked + label {
    text-decoration: line-through;
    font-style: italic; }
  .styled-checkbox:disabled + label {
    color: #b8b8b8;
    cursor: auto; }
  .styled-checkbox:disabled + label:before {
    box-shadow: none;
    background: #ddd; }
  .styled-checkbox:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
